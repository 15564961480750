import Swiper from 'swiper';
import {Navigation, Pagination} from "swiper/modules";

export class PropertySlider {
  constructor() {
    new Swiper('.mySwiper', {
      modules: [Pagination, Navigation],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,

      breakpoints: {
        1000: {
          slidesPerView: 3,
        },
        600: {
          slidesPerView: 2,
        },
      },
    })
  }
}
