import Isotope from 'isotope-layout';

export class ArchiveIsotope {
  constructor() {
    const gridItems = document.querySelector('.grid-isotop')
    const filterItems = document.querySelectorAll('.filter-item')

    if (gridItems) {
      let iso = new Isotope(gridItems, {
        itemSelector: '.element-item',
        layoutMode: 'fitRows',
        fitRows: {
          gutter: 36,
        },
      })

      filterItems.forEach(function (el) {
        el.addEventListener('click', function (e) {
          filterItems.forEach(function (element) {
            element.classList.remove('active')
          })

          e.target.classList.add('active')

          iso.arrange({
            filter: e.target.getAttribute('data-filter'),
          })
        })
      })
    }
  }
}
