import gsap from 'gsap'

export class Header {
    constructor()
    {
        this.banner = document.querySelector('header.banner')

        this.tl = gsap.timeline({paused:true});
        this.animation()

        const burger = document.querySelector('#burger')
        if (burger) {
            burger.addEventListener('click', evt => {
                evt.preventDefault()
                burger.classList.toggle('active')
                this.banner.classList.toggle('expanded')

                if (this.banner.classList.contains('expanded')) {
                    this.tl.play()
                } else {
                    this.tl.reverse()
                }
            })
        }
    }

    animation()
    {
        const nav = this.banner.querySelector('.nav-primary.mobile')
        const menu_items = nav.querySelectorAll('ul .menu-item')

        this.tl.to(nav, {x: 0})
        this.tl.to(menu_items, {x: 0, opacity: 1, stagger: 0.15})
    }
}
