import $ from 'jquery';
import 'jquery-parallax'

export class ParallaxThumbnail {

  constructor() {
    const images = document.querySelectorAll('.parallax-')

    // if (images) {
    //   images.forEach(function (e) {
    //     new simpleParallax(e, {
    //       scale: 2,
    //       orientation: 'down',
    //       maxTransition: 1,
    //     })
    //   })
    // }



  }
}
