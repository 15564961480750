import {Header} from '../util/header';
import {ParallaxThumbnail} from '../util/ParallaxThumbnail';
import {PropertySlider} from '../util/propertySlider';
import {ArchiveIsotope} from "@js/util/ArchiveIsotope";
export default {
  init() {
    new Header()
    new ParallaxThumbnail()
    new PropertySlider()
    new ArchiveIsotope()
  },
  finalize() {
  },
};
